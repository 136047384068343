import React, { useEffect } from "react"
import { navigate } from "gatsby"

const OperationClimate = () => {
  // This is a redirect route to the home page for tracking a traffic source
  useEffect(() => {
    navigate("/")
  }, [])

  return <div />
}

export default OperationClimate
